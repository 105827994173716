import { getQueryString, http, throwIfError } from './tools';

export const apiCards = {
  cardsList,

  getCardToPhone,
  getOwner,
  getCoOwners,
  saveOwner,
  saveCoowner,
  getPdf,
  getPdfBase64,
  issueVc,
  history,
  tarifications,
  subscriptions

};

async function cardsList () {
  const response = await http.get('api/cards/cardsList');
  throwIfError(response);
  return response.data;
}

async function getOwner (card) {
  const queryString = getQueryString({ card });
  const response = await http.get(`api/cards/owner${queryString}`);
  throwIfError(response);
  return response.data;
}

async function getCoOwners (card) {
  const queryString = getQueryString({ card });
  const response = await http.get(`api/cards/coOwners${queryString}`);
  throwIfError(response);
  return response.data;
}

async function saveOwner (owner) {
  const response = await http.post('api/cards/UpdateOwner', owner);
  throwIfError(response);
  return response.data;
}

async function saveCoowner (coowner) {
  const response = await http.post('api/cards/UpdateCoOwner', coowner);
  throwIfError(response);
  return response.data;
}

async function getCardToPhone (token, card) {
  const response = await http.post('api/getCardToPhone', { token, card });
  throwIfError(response);
  return response.data;
}

async function issueVc (card) {
  const queryString = getQueryString({ card });
  const response = await http.post(`api/cards/IssueVc${queryString}`);
  throwIfError(response);
  return response.data;
}

async function getPdf (token, card) {
  const response = await http.get('api/getPdf', { responseType: 'arraybuffer' });
  throwIfError(response);
  return response;
}

async function getPdfBase64 (token, card) {
  const response = await http.get('api/getPdfBase64');
  throwIfError(response);
  return response.data;
}

async function history (card) {
  const queryString = getQueryString({ card });
  const response = await http.get(`api/cards/history${queryString}`);
  throwIfError(response);
  return response.data;
}

async function tarifications (card) {
  const queryString = getQueryString({ card });
  const response = await http.get(`api/cards/tarifications${queryString}`);
  throwIfError(response);
  return response.data;
}

async function subscriptions (card) {
  const queryString = getQueryString({ card });
  const response = await http.get(`api/cards/subscriptions${queryString}`);
  throwIfError(response);
  return response.data;
}

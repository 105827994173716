import { getQueryString, http, throwIfError } from './tools';

export const apiShared = {
  config,
  getLogo,
  getDoc
};

async function config () {
  const response = await http.get('api/shared/config');
  throwIfError(response);
  return response.data;
}

async function getLogo () {
  const response = await http.getBlob('api/shared/getLogo', { options: { nullIfError: true } });
  if (response === null)  
    return null;
  return response.data;
}

async function getDoc (documentName) {
  const queryString = getQueryString({ documentName });
  const response = await http.getBlob(`api/shared/getDoc${queryString}`, { options: { nullIfError: true } });
  if (response === null)  
    return null;
  return response.data;
}

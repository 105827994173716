import {
  processActionAsync
} from '@/store/process-action';
import {
  ACTION_AUTH_CONFIRM_PHONE,
  ACTION_AUTH_PHONE_EXISTS,
  ACTION_AUTH_SEND_CONFIRM_CODE_NO_PROFILE,
  ACTION_AUTH_SEND_CONFIRM_CODE_PROFILE,
  ACTION_AUTH_LOGOUT,
  ACTION_AUTH_RESEND_CONFIRM_CODE
} from '@/store/const-actions';
import {
  apiAuth
} from '@/api/api-auth';
import {
  MUTATION_AUTH_INIT,
  MUTATION_AUTH_REMOVE_PHONE_CONFIRMATION_TOKEN,
  MUTATION_AUTH_SET,
  MUTATION_AUTH_SET_PHONE_CONFIRMATION_TOKEN,
  MUTATION_CARDS_SET_INIT,
  MUTATION_PROFILE_INIT,
  MUTATION_PROGRESS_INIT
} from './const-mutations';
import { formatStringDateFromInput } from '@/tools/format';

function initState () {
  return {
    phone: null,
    needRegisterPhone: null,
    needChildrenInProfile: true
  };
}
const state = initState();

const actions = {

  async [ACTION_AUTH_PHONE_EXISTS] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const result = await apiAuth.phoneExists(context.state.phone);
      context.commit(MUTATION_AUTH_SET, {
        needRegisterPhone: result.data.needRegisterPhone,
        needChildrenInProfile: result.data.needChildrenInProfile
      });

      if (result.data.needRegisterPhone !== true)
        await context.dispatch(ACTION_AUTH_SEND_CONFIRM_CODE_NO_PROFILE, {
          ...payload,
          noProgress: true
        });

      return result.data;
    });
  },

  async [ACTION_AUTH_SEND_CONFIRM_CODE_NO_PROFILE] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const result = await apiAuth.sendConfirmCode(context.state.phone, null);
      return result.data;
    });
  },

  async [ACTION_AUTH_SEND_CONFIRM_CODE_PROFILE] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const profile = {
        owner: {
          firstName: context.rootState.profile.owner.firstName,
          lastName: context.rootState.profile.owner.lastName,
          middleName: context.rootState.profile.owner.middleName,
          birthday: formatStringDateFromInput(context.rootState.profile.owner.birthday),
          email: context.rootState.profile.owner.email, 
          gender: context.rootState.profile.owner.gender,
          mailingConsent: context.rootState.profile.owner.mailingConsent,
          inBonusProgram: context.rootState.profile.owner.inBonusProgram
        },
        coOwners: context.rootState.profile.coowners.map((item) => ({
          firstName: item.firstName,
          lastName: item.lastName,
          birthday: formatStringDateFromInput(item.birthday),
          gender: item.gender
        }))
      };
      const result = await apiAuth.sendConfirmCode(context.state.phone, profile);
      return result;
    });
  },

  async [ACTION_AUTH_RESEND_CONFIRM_CODE] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const result = await apiAuth.reSendConfirmCode(context.state.phone);
      return result.data;
    });
  },

  async [ACTION_AUTH_CONFIRM_PHONE] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const result = await apiAuth.confirmPhone(context.state.phone, payload.code);
      const phoneConfirmationToken = `${state.phone} ${result.data.token}`;
      context.commit(MUTATION_AUTH_SET_PHONE_CONFIRMATION_TOKEN, {
        phoneConfirmationToken: phoneConfirmationToken
      });
      return result.data;
    });
  },

  async [ACTION_AUTH_LOGOUT] (context, payload) {
    context.commit(MUTATION_AUTH_INIT);
    context.commit(MUTATION_AUTH_REMOVE_PHONE_CONFIRMATION_TOKEN);
    context.commit(MUTATION_CARDS_SET_INIT);
    context.commit(MUTATION_PROFILE_INIT);
    context.commit(MUTATION_PROGRESS_INIT);
  }
};

const mutations = {

  [MUTATION_AUTH_INIT] (state) {
    state = Object.assign(state, initState());
  },

  [MUTATION_AUTH_SET] (state, payload) {
    state = Object.assign(state, {
      ...payload
    });
  },

  [MUTATION_AUTH_SET_PHONE_CONFIRMATION_TOKEN] (state, payload) {
    localStorage.setItem('gkPhoneConfirmationToken', payload.phoneConfirmationToken);
  },

  [MUTATION_AUTH_REMOVE_PHONE_CONFIRMATION_TOKEN] (state) {
    localStorage.removeItem('gkPhoneConfirmationToken');
  }

};

export default {
  state,
  actions,
  mutations
};

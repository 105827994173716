import { apiCards } from '@/api/api-cards';
import { formatStringDateToInput, formatStringDateFromInput } from '@/tools/format';
import Vue from 'vue';
import {
  ACTION_PROFILE,
  ACTION_PROFILE_GET_COOWNERS,
  ACTION_PROFILE_GET_OWNER,
  ACTION_PROFILE_SAVE_COOWNER,
  ACTION_PROFILE_SAVE_OWNER
} from './const-actions';
import {
  MUTATION_AUTH_SET,
  MUTATION_PROFILE_ADD_COOWNER,
  MUTATION_PROFILE_DELETE_COOWNER,
  MUTATION_PROFILE_EDIT_OWNER,
  MUTATION_PROFILE_INIT,
  MUTATION_PROFILE_SET_COOWNERS,
  MUTATION_PROFILE_SET_OWNER,
  MUTATION_PROFILE_UPDATE_COOWNER
} from './const-mutations';
import { processActionAsync } from './process-action';

export function newOwner () {
  return {
    cardNo: null,
    lastName: null,
    firstName: null,
    middleName: null,
    email: null,
    birthday: null,
    birthdayInitValue: null, // Нужно для понимания было ли поле пустое в анкете или было заполненное
    gender: null,
    genderInitValue: null, // Нужно для понимания было ли поле пустое в анкете или было заполненное
    mailingConsent: false,
    inBonusProgram: false,
    processingConsent: false
  };
}

export function newCoowner (index, cardNo) {
  return {
    index: index,
    cardNo: cardNo,
    name: null,
    firstName: null,
    lastName: null,
    birthday: null,
    gender: null
  };
}

function initState () {
  return {
    owner: newOwner(),
    coowners: []
  };
}

function mapOwnerFromApi (value) {
  const birthday = formatStringDateToInput(value.birthday);
  const result = {
    ...value,
    birthday: birthday,
    birthdayInitValue: birthday,
    genderInitValue: value.gender
  };
  return result;
}

const state = initState();

const actions = {

  async [ACTION_PROFILE] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      await context.dispatch(ACTION_PROFILE_GET_OWNER, { ...payload, noProgress: true });
      await context.dispatch(ACTION_PROFILE_GET_COOWNERS, { ...payload, noProgress: true });
    });
  },

  async [ACTION_PROFILE_GET_OWNER] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const result = await apiCards.getOwner(payload.card);
      context.commit(MUTATION_PROFILE_SET_OWNER, mapOwnerFromApi(result.data));
      context.commit(MUTATION_AUTH_SET, { phone: result.data.phone });
      return result.data;
    });
  },

  async [ACTION_PROFILE_GET_COOWNERS] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const result = await apiCards.getCoOwners(payload.card);

      const preparedCoowners = resultToCoowners(result.data);
      context.commit(MUTATION_PROFILE_SET_COOWNERS, preparedCoowners);

      return preparedCoowners;
    });
  },

  async [ACTION_PROFILE_SAVE_OWNER] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const tempOwner = { ...context.state.owner };
      tempOwner.birthday = formatStringDateFromInput(tempOwner.birthday);
      const result = await apiCards.saveOwner(tempOwner);
      context.commit(MUTATION_PROFILE_SET_OWNER, mapOwnerFromApi(result.data));
      return result.data;
    });
  },

  async [ACTION_PROFILE_SAVE_COOWNER] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const result = await apiCards.saveCoowner({
        card: context.state.owner.card,
        id: payload.coowner.index,
        name: payload.coowner.firstName,
        birthday: formatStringDateFromInput(payload.coowner.birthday),
        gender: payload.coowner.gender
      });
      const preparedCoowners = resultToCoowners(result.data);
      context.commit(MUTATION_PROFILE_SET_COOWNERS, preparedCoowners);

      return preparedCoowners;
    });
  }

};

function resultToCoowners (data) {
  let coowners = [];
  if (Array.isArray(data))
    coowners = data;
  const result = coowners.map((item) => ({
    cardNo: item.cardNo,
    index: item.id,
    firstName: item.name,
    gender: item.gender,
    birthday: formatStringDateToInput(item.birthday)
  }));
  return result;
}

const mutations = {

  [MUTATION_PROFILE_INIT] (state) {
    state = Object.assign(state, initState());
  },

  [MUTATION_PROFILE_EDIT_OWNER] (state, payload) {
    Object.assign(state.owner, {}, { ...payload });
  },

  [MUTATION_PROFILE_SET_OWNER] (state, payload) {
    state.owner = Object.assign({}, { ...payload });
  },

  [MUTATION_PROFILE_SET_COOWNERS] (state, payload) {
    state.coowners = payload;
  },

  [MUTATION_PROFILE_ADD_COOWNER] (state, payload) {
    var max = -1;
    for (let index = 0; index < state.coowners.length; index++) {
      if (state.coowners[index].index > max)
        max = state.coowners[index].index;
    }
    const newItem = { ...payload, index: max + 1 };
    state.coowners.push(newItem);
  },

  [MUTATION_PROFILE_UPDATE_COOWNER] (state, payload) {
    const index = state.coowners.findIndex(item => item.index === payload.index);
    Vue.set(state.coowners, index, payload);
  },

  [MUTATION_PROFILE_DELETE_COOWNER] (state, payload) {
    const index = state.coowners.findIndex(item => item.index === payload.index);
    state.coowners.splice(index, 1);
  }
};

export default {
  state,
  actions,
  mutations
};

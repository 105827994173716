import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/store/module-auth';
import cards from '@/store/module-cards';
import profile from '@/store/module-profile';
import progress from '@/store/module-progress';
import shared from '@/store/module-shared.js';

Vue.use(Vuex);

export default new Vuex.Store({ 
  state: {
    onButtonClickFlag: false,
    currentIndex: 0
  },
  mutations: {
    setOnButtonClickFlag (state, value) {
      state.onButtonClickFlag = value;
    },
    SET_0N_BUTTON_CLICK_FLAG (state, flag) {
      state.onButtonClickFlag = flag;
    }
  },

  modules: {
    auth,
    cards,
    profile,
    progress,
    shared
  }
});
